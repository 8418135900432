

import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { EvalutionRule, EvalutionTerm, EvaluationLevel } from '@/types/evaluation'
import { ElForm } from 'element-ui/types/form'
import { FileInfo, Page } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { UploadFile }
})
export default class EvaluationProjectAdd extends Vue {
  @Ref('form') readonly form!: ElForm
  tableData: EvalutionTerm[] = []
  levelItems: EvaluationLevel[] = []
  currentData = {}
  dialogVisible = false
  searchForm = {
    projectId: '',
    evaluationMonth: '',
    evaluationExplain: ''
  }

  pickerOptions = {
    disabledDate: (time: Date) => {
      const date = this.$dayjs().subtract(1, 'month').format('YYYY/MM')
      const current = this.$dayjs(date).valueOf()
      return time.getTime() > current
    }
  }

  rules = {
    projectId: [{ required: true, message: '请选择所属项目' }],
    evaluationMonth: [{ required: true, message: '请选择考核月份' }]
  }

  evaluationExplain = ''
  fileList: Array<FileInfo> = []

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  created () {
    this.loadEvaluationTerm()
    this.loadEvaluationLevel()
  }

  loadEvaluationTerm () {
    this.$axios.get<EvalutionRule[]>(this.$apis.evaluation.selectYhEvaluationTermByList).then(res => {
      const items: EvalutionTerm[] = []
      res.forEach(item => {
        if (item.childNode && item.childNode.length) {
          item.childNode.forEach((childNode, index) => {
            items.push({
              colspan: index === 0 ? item.childNode.length : 0,
              termId: item.termId,
              termName: item.termName, // 考评项名称
              termWeight: item.termWeight, // 考评权重
              termChildName: childNode.termName,
              parentTermId: item.termId,
              termDetailed: childNode.termDetailed, // 考评项细则
              childTermId: childNode.termId,
              termScore: null,
              termScoreReason: '',
              finalScore: null
            })
          })
        }
      })
      this.tableData = items
    })
  }

  @Watch('searchForm', { deep: true })
  loadProjectScore () {
    const data = this.tableData.find(item => item.termId === '20210929000001')
    if (this.searchForm.projectId && this.searchForm.evaluationMonth && data) {
      this.$axios.get(this.$apis.evaluation.selectIssueByScore, this.searchForm).then(res => {
        data.termScore = +res
        data.finalScore = parseFloat((data.termScore * parseInt(data.termWeight) / 100).toFixed(2)) || null
      })
    }
  }

  loadEvaluationLevel () {
    this.$axios.get<Page<EvaluationLevel>>(this.$apis.evaluation.selectYhEvaluationGradeByList).then(res => {
      this.levelItems = res.list
    })
  }

  computedScore (row: EvalutionTerm, index: number) {
    const items = this.tableData.slice(index, index + row.colspan)
    const count = items.reduce((prev, next) => {
      if (next.termScore) {
        return prev + next.termScore
      } else {
        return prev
      }
    }, 0) / items.length
    const formatterCount = parseFloat((count * (parseInt(row.termWeight) / 100)).toFixed(2)) || 0
    row.finalScore = formatterCount as number
    return formatterCount
  }

  comfirmAddEvaluation () {
    this.form.validate().then(() => {
      const level1: any = []
      const level2: any = []
      const items = JSON.parse(JSON.stringify(this.tableData)) as EvalutionTerm[]
      items.forEach((item, index) => {
        if (item.colspan > 0) {
          level1.push({
            termId: item.termId,
            termName: item.termName,
            parentTermId: '0',
            termWeight: item.termWeight
          })
          for (let i = index; i < index + item.colspan; i++) {
            level2.push({
              termId: items[i].childTermId,
              parentTermId: item.termId,
              termName: items[i].termChildName,
              termDetailed: items[i].termDetailed,
              termScore: items[i].termScore,
              termScoreReason: items[i].termScoreReason
            })
          }
        }
      })
      for (let i = 0; i < level2.length; i++) {
        // 巡查考评来源来自问题，无须输入
        if (level2[i].parentTermId !== '20210929000001') {
          if (!level2[i].termScore && level2[i].termScore !== 0) {
            this.$message.warning('请输入得分')
            return
          }
          if (!(level2[i].termScore >= 0 && level2[i].termScore <= 100)) {
            this.$message.warning('得分在0-100之间')
            return
          }
          if (!(/(^[1-9]\d*$)/.test(level2[i].termScore)) && (level2[i].termScore) !== 0) {
            this.$message.warning('得分为0-100整数')
            return
          }
        }
      }
      // 确认提交
      let count = 0
      for (const score of this.tableData) {
        if (score.colspan > 0) {
          count += score.finalScore as number
        }
      }
      let level = ''
      if (count === 100) {
        level = this.levelItems[0].gradeName
      } else {
        this.levelItems.forEach(item => {
          if (count >= parseInt(item.gradeLo) && count < parseInt(item.gradeUp)) {
            level = item.gradeName
          }
        })
      }
      count = parseFloat(count.toFixed(2)) || 0
      const projectName = this.normalProjectList.find((item: any) => item.projectId === this.searchForm.projectId).projectName
      this.$axios.get(this.$apis.evaluation.isYhEvaluationMobile, {
        evaluationMonth: this.searchForm.evaluationMonth,
        projectId: this.searchForm.projectId
      }).then(res => {
        if (!res) {
          this.$confirm(`'${projectName}'的${this.searchForm.evaluationMonth}的考评总得分为${count}，考评等级为'${level}'，请确定！`, '提示').then(() => {
            this.save(level1, level2)
          })
        } else {
          this.$message.warning(`${this.searchForm.evaluationMonth}月份该项目已考评,请不要重复操作!`)
        }
      })
    })
  }

  showTermDetailed (row: EvalutionTerm) {
    this.currentData = row
    this.dialogVisible = true
  }

  objectSpanMethod ({ row, column, rowIndex, columnIndex }: any) {
    if (columnIndex === 0 || columnIndex === 1 || columnIndex === 5) {
      if (row.colspan > 0) {
        return {
          rowspan: row.colspan,
          colspan: 1
        }
      } else {
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }

  onSuccess (file: FileInfo) {
    this.fileList.push(file)
  }

  fileRemove (index: number) {
    this.fileList.splice(index, 1)
  }

  save (level1: any, level2: any) {
    this.$axios.post(this.$apis.evaluation.insertYhEvaluation, {
      ...this.searchForm,
      evaluationExplain: this.evaluationExplain,
      fileList: this.fileList,
      yhEvaluationScoreList: level1.concat(level2)
    }).then(() => {
      this.$router.back()
    })
  }
}
